<template>
  <div>
    <MyHeader :title="$t('lang.menu_patrolplanrecord')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />

    <van-pull-refresh v-model="loading" @refresh="onRefresh">
    <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.listend')" :immediate-check="false"
      direction="down" @load="onLoad">
      <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.placeName" is-link
        :icon="mData.isMiss || mData.isLong || mData.isShort ? 'warning' : ''" :class="mData.isMiss
            ? mData.endTime > new Date()
              ? 'isWait'
              : 'isMiss'
            : mData.isLong || mData.isShort
              ? 'warning'
              : ''
          " :to="{
      name: 'PollingPlanDetail',
      params: {
        id: mData.id,
        title: mData.placeName,
        isMiss: mData.isMiss,
        memo: mData.comment,
        isaudit: mData.isAudit,
      },
    }">
        <template slot="label">
          <span style="display: block">{{ $t("lang.arriveTime") }}：{{
            mData.isMiss
            ? "【" +
            (mData.endTime > new Date()
              ? $t("lang.notstarted")
              : $t("lang.miss")) +
            "】"
            : mData.arriveTime != undefined
              ? $Tools.FormatDate1(mData.arriveTime)
              : ""
          }}</span>
          <span style="display: block">{{ $t("lang.interval") }}：{{ mData.interval }}
            {{ $t("lang.second")
            }}<span style="margin-left: 20px">{{ $t("lang.elapsedTime") }}：{{ mData.elapsedTime }}
              {{ $t("lang.second") }}</span></span>
          <span style="display: block">{{ $t("lang.router_user") }}：{{
            mData.routeName +
            (mData.inspectorName != undefined
              ? " " + mData.inspectorName
              : "")
          }}<br />
            {{ $t("lang.startTime") }}：{{ $Tools.FormatDate1(mData.startTime)
            }}<br />
            {{ $t("lang.endTime") }}：{{
              $Tools.FormatDate1(mData.endTime)
            }}</span>
        </template>
      </van-cell>
    </van-list>
    </van-pull-refresh>
    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->
  </div>
</template>

<style scoped>
.warning .van-cell__left-icon {
  color: #663366;
  font-size: 20px;
}

.warning .van-cell__title {
  color: #663366;
}

.isMiss .van-cell__left-icon {
  color: red;
  font-size: 20px;
}

.isMiss .van-cell__title {
  color: red;
}

.isWait .van-cell__left-icon {
  color: blue;
  font-size: 20px;
}

.isWait .van-cell__title {
  color: blue;
}
</style>

<script>
import MyHeader from "@/components/Header.vue";
import { GetPollingPlan, ExportPatrolPlanRecords } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      lstData: [],
      loading: false,
      finished: false,
      page: 1,
      backUrl:""
      //showHint: true,
    };
  },

  mounted() {
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/query/index";
    else
      this.backUrl = "/query/appindex";
    //console.log("mounted");
  },

  activated() {
    //console.log("activated");
    if (this.$route.params.p) {
      if (this.$route.params.p.event == "export") this.Export();
      else {
        this.page = 1;
        this.lstData = [];
        this.Query();
      }
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: {
          title: this.$t("lang.menu_patrolplanrecord"),
          f: "PollingPlan",
        },
      });
    },
    
    onRefresh(){
      this.page=1;
      this.Query();
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        // this.showHint = true;
        return;
      }

      let that = this;
      that.loading = true;

      GetPollingPlan(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strPlaceIds: p.mAddr,
          strGroupIds: p.mGroup,
          strInspectorIds: p.mUser,
          queryType: p.mRecord,
          sort: p.mOrder,
          page: this.page,
          limit: p.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              if (this.page == 1) that.lstData = ret.data;
              else that.lstData.push(...ret.data);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    Export() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) return;

      let par = {
        url:
          ExportPatrolPlanRecords() +
          "?token=" +
          this.$store.getters.getToken +
          "&startTime=" +
          p.mDate1 +
          "&endTime=" +
          p.mDate2 +
          "&strCompanyIds=" +
          p.mCompany +
          "&strDepartmentIds=" +
          p.mDept +
          "&strRouteIds=" +
          p.mLine +
          "&strPlaceIds=" +
          p.mAddr +
          "&strGroupIds=" +
          p.mGroup +
          "&strInspectorIds=" +
          p.mUser +
          "&queryType=" +
          p.mRecord +
          "&sort=" +
          p.mOrder +
          "&page=1&limit=1000000",
        fileName:
          "巡检计划核查_" +
          this.$Tools.FormatDate("YYYYmmdd_HHMMSS", new Date()) +
          ".xls",
      };

      //console.log(JSON.stringify(par));
      try {
        window.downloadFile.postMessage(JSON.stringify(par));
        //window.openByBrowser.postMessage(par.url);
      } catch (e) {
        console.log(e);
      }
    },

    onLoad() {
      this.Query();
    },
  },
};
</script>
